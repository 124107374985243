import {  getRandomHex } from '@xy/core'
import { getUUID } from "@/utils/strUtil";
export function getIP(callback) {
  window.$.ajax({
    type: 'get',
    // url: 'https://ipv4.icanhazip.com/',
    url: 'https://geo.xiwshijieheping.com/ip/area',
    success: function (data) {
      console.log(data)
      const { ip } = data
      callback(ip.replace(/\r|\n/gi, ''), data.area)
    },
    error: function (err) {
      callback('')
    }
  })
}
function ajax(options) {
	var xhr = new XMLHttpRequest();
	var data = options.data, url = options.url, method = options.method;
	if (data && "string" != typeof data) {
		data = JSON.stringify(data);
	}
	if (method != "POST" && data) {
		url = url + (url.indexOf('?') > -1 ? "&" : "?") + data;
		data = null;
	}
	xhr.onreadystatechange = function () {
		if (xhr.readyState == 4) {
			if (xhr.status == 200) {
				var res = xhr.response || xhr.responseText || {};
				options.success
				&& options.success(typeof res == "string" ? JSON
					.parse(res) : res);
			} else {
				options.error && options.error(xhr, xhr.statusText);
			}
			options.complete && options.complete(xhr);
		}
	};
	xhr.ontimeout = function () {
		options.error && options.error(xhr, xhr.statusText);
	};

	try {
		xhr.open(method, url, options.async !== false);
		xhr.withCredentials = true;
		try {
			if (xhr.setRequestHeader && options.contentType) {
				xhr.setRequestHeader("Content-Type", options.contentType);
			}
			if (options.timeout) {
				xhr.timeout = options.timeout
			}
		} catch (e1) {
		}
		xhr.send(data || null);
	} catch (e2) {
	}
	return xhr;
}
// 进入落地页上报
export function sessionReport(param) {
  let uuid = localStorage.getItem('KEY_UUID')
  if (!uuid) {
    uuid = getRandomHex(16)
    localStorage.setItem('KEY_UUID', uuid)
  }
  const data = {
    type: 'page_share_success',
    inviteCode: deviceInfo.invitationCode,
    deviceInfo: {
      uuid: uuid
    },
    appInfo: {
      appId: deviceInfo.appId,
      packageCode: deviceInfo.packageCode
    },
    terminalInfo: {
      terminal: deviceInfo.isiOS ? 1 : deviceInfo.isAndroid ? 2 : 4,
      ip: param.ip
      // networkEnv: navigator.connection.effectiveType,
    }
  }
  window.$.ajax({
    type: 'post',
    url: '/api/open/app/user_session/report',
    data: JSON.stringify({ data }),
    contentType: 'application/json;charset=utf-8',
    headers: {
      Authorization: 'M2ZoCmm/GT12Q5ecOsxefz35aqT02+ZlzSawhaf5D5Q=',
      'X-Client-Identity': 'APP/1;com.smarter.app;1.0.0;' + deviceInfo.packageCode + ' SDI/D4843E87-6CB4-4B4F-82C7-6A6DD02C5A84;0 SYS/IOS;12 FM/Apple;iPhone10 NE/WIFI LANG/zh-CN',
      'X-Client-TimeStamp': new Date().getTime()
    },
    success: function (res) {
      console.log(res)
    }
  })
}
export function addLiquidId(data, callback = () => {}) {
  data.uuid = getUUID()
  window.$.ajax({
    type: 'post',
    url: `/api/open/user_c/inviteRelation/add`,
    data: JSON.stringify({ data }),
    // dataType: 'json',
    contentType: 'application/json;charset=utf-8',
    headers: {
      Authorization: 'M2ZoCmm/GT12Q5ecOsxefz35aqT02+ZlzSawhaf5D5Q=',
      'X-Client-Identity': 'APP/1;com.smarter.app;1.0.0;' + deviceInfo.packageCode + ' SDI/D4843E87-6CB4-4B4F-82C7-6A6DD02C5A84;0 SYS/IOS;12 FM/Apple;iPhone10 NE/WIFI LANG/zh-CN',
      'X-Client-TimeStamp': new Date().getTime()
    },
    success: function (res) {
      console.log('addLiquidId==', res)
      if (res.data) {
        localStorage.setItem('beInvitedUuid', res.data.beInvitedUuid)
        // 执行回调（配置图片域名数据）
        res.data.appId && callback(res.data.appId)
      }
    }
  })
}
export function isValidBrowser() {
	var ua = navigator.userAgent.toLowerCase()
	if (ua.match(/MicroMessenger\/[0-9]/i)) {
	  // return 'weixin'
	  return false
	}
  
	if (ua.match(/WeiBo/i) == "weibo") {
	  //在新浪微博客户端打开
	  // $('.from').html("微博");
	  return false
	}
  
	if (ua.match(/QQ\/[0-9]/i)) {
	  // return 'QQ'
	  return false
	}
	return true
  }
// 新增上报接口
export function clickReport(data, callback = () => {}) {
  data.uuid = getUUID()
  return new Promise((resolve, reject) => {
    window.$.ajax({
      type: 'post',
      url: `/api/open/user_c/inviteRelation/clickReport`,
      data: JSON.stringify({ data }),
      // timeout: 3000,
      // dataType: 'json',
      contentType: 'application/json;charset=utf-8',
      headers: {
        Authorization: 'M2ZoCmm/GT12Q5ecOsxefz35aqT02+ZlzSawhaf5D5Q=',
        'X-Client-Identity': 'APP/1;com.smarter.app;1.0.0;' + deviceInfo.packageCode + ' SDI/D4843E87-6CB4-4B4F-82C7-6A6DD02C5A84;0 SYS/IOS;12 FM/Apple;iPhone10 NE/WIFI LANG/zh-CN',
        'X-Client-TimeStamp': new Date().getTime()
      },
      success: function (res) {
        console.log('clickReport==', res)
        resolve('reportSuccess')
      },
      error: function (err) {
        reject(err)
      }
    })
  })
}