import axios from 'axios'
import { Message } from 'element-ui'
import { getRandomString } from '@/utils/strUtil'
import { encryptData, decryptData } from '@/utils/params_nav'

// 是否需要加密
// const useEncrypt = process.env.NODE_ENV !== 'development'
const useEncrypt = false
const aes_key = getRandomString(16)
let api_param_cfg = {}
const http = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? '/' : '/api',
  // baseURL: '/',
  timeout: 10 * 1000
})

// 请求拦截
http.interceptors.request.use(
  (config) => {

    // 接口加密处理  
    if (useEncrypt && config.method == 'post') {
      api_param_cfg = { key: aes_key, url: config.url }
      const en_r_data = encryptData(config.data, api_param_cfg)
      config.data = en_r_data || config.data
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

http.interceptors.response.use(
  (response) => {
    // 请求成功
    // blob数据直接返回
    if (response.request.responseType === 'blob') {
      return response
    } else {
      if (response.headers['x-server-encrypt'] == '1') {
        try {
          const resData = decryptData(response.data.data, api_param_cfg)
          response.data.data = resData
          return response.data
        } catch (error) {
          return 'err'
        }
      } else {
        let data = response?.data
        return data
      }
    }
  },
  (error) => {
    // 请求失败
    if (error && error?.response?.data) {
      const messageStr = error.response?.data?.message
      switch (error.response.status) {
        case 404:
          // 认证失败
          Message.error({
            showClose: true,
            message: '资源未找到'
          })
          break
        default:
          // 如果以上都不是的处理
          Message.error({
            showClose: true,
            message: messageStr
          })
      }
    } else {
      Message.error({
        showClose: true,
        message: '请求服务器失败'
      })
    }
    return Promise.reject(error)
  }
)

export default http
